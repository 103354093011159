import { Maximize2 } from "react-feather";
import { useState, cloneElement, isValidElement } from "react";

export interface MaximizeProps {
    title: string;
    children?: React.ReactNode;
}

const Maximize: React.FC<MaximizeProps> = ({ title, children }) => {
    const [isMaximized, setIsMaximized] = useState(false);

    const removeHeightClasses = (child: React.ReactElement) => {
        const classNames = (child.props.className || '').split(' ').filter((className: string) => 
            !className.includes('h-') && 
            !className.includes('height') && 
            !className.includes('max-h-')
        ).join(' ');

        return cloneElement(child, {
            ...child.props,
            className: classNames,
            style: {
                ...child.props.style,
                height: isMaximized ? '100%' : child.props.style?.height
            }
        });
    };

    const renderChildren = () => {
        if (!children) return null;
        if (!isValidElement(children)) return children;

        return removeHeightClasses(children);
    };

    return (
        <>
            <div className="card card-compact w-full bg-base-100 border-2 border-[#143969] mt-6 mr-6 overflow-hidden">
                <div className="card-body border-b-2 border-b-[#143969] flex flex-row justify-between bg-[#143969]">
                    <h2 className="card-title text-white">{title}</h2>
                    <button className="btn btn-square btn-sm bg-white hover:bg-gray-100 border-none" onClick={() => setIsMaximized(true)}>
                        <Maximize2 width={18} className="text-[#143969]" />
                    </button>
                </div>
                <div className="overflow-hidden">
                    {!isMaximized ? children : renderChildren()}
                </div>
            </div>

            {/* Modal for maximized view */}
            {isMaximized && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-11/12 h-5/6 rounded-xl p-6 flex flex-col border-2 border-[#143969] overflow-hidden">
                        <div className="flex justify-between items-center mb-4 bg-[#143969] -m-6 p-6 rounded-t-lg">
                            <h2 className="text-2xl font-bold text-white">{title}</h2>
                            <button 
                                className="btn btn-sm btn-circle bg-white hover:bg-gray-100 border-none text-[#143969]"
                                onClick={() => setIsMaximized(false)}
                            >
                                ✕
                            </button>
                        </div>
                        <div className="flex-grow overflow-hidden mt-6">
                            {renderChildren()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Maximize;
