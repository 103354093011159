// components/ThemeSwitcher.js
import React, { useEffect, useState } from 'react';

const themes = [
  "light",
  "adrsta"
  // "dark",
  // "cupcake",
  // "bumblebee",
  // "emerald",
  // "corporate",
  // "synthwave",
  // "retro",
  // "cyberpunk",
  // "valentine",
  // "halloween",
  // "garden",
  // "forest",
  // "aqua",
  // "lofi",
  // "pastel",
  // "fantasy",
  // "wireframe",
  // "black",
  // "luxury",
  // "dracula",
  // "cmyk",
  // "autumn",
  // "business",
  // "acid",
  // "lemonade",
  // "night",
  // "coffee",
  // "winter",
  // "dim",
  // "nord",
  // "sunset",
]; // Add more themes as needed

const ThemeSwitcher = () => {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
    changeTheme(savedTheme);
  }, []);

  const changeTheme = (theme: string) => {
    if (!themes.includes(theme)) {
      console.error(`Theme "${theme}" not found. Falling back to default theme.`);
      theme = 'light';
    }
    document.documentElement.setAttribute('data-theme', theme); // Set the data-theme attribute
    document.documentElement.classList.remove(...themes); // Remove all theme classes
    document.documentElement.classList.add(theme); // Add selected theme class
    localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  return (
    <select className="select select-ghost select-sm w-fit" onChange={(e) => changeTheme(e.target.value)} value={theme}>
      {themes.map((theme) => (
        <option key={theme} value={theme}>{theme.toUpperCase()}</option>
      ))}
    </select>
  );
};

export default ThemeSwitcher;
