import React, { useState, useRef, useEffect } from "react";
import { Info } from 'react-feather';
import { createPortal } from 'react-dom';

interface InfoProps {
    info: string
}

const InfoIcon: React.FC<InfoProps> = ({ info }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState({});
    const iconRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isHovered && iconRef.current && tooltipRef.current) {
            const iconRect = iconRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();
            const screenWidth = window.innerWidth;
            
            // Calculate base position (centered above icon)
            let left = iconRect.left + iconRect.width / 2;
            let transformX = -50;  // Default center alignment

            // Check if tooltip would be clipped on the left
            if (left - (tooltipRect.width / 2) < 0) {
                left = tooltipRect.width / 2;
                transformX = -50;
            }
            // Check if tooltip would be clipped on the right
            else if (left + (tooltipRect.width / 2) > screenWidth) {
                left = screenWidth - (tooltipRect.width / 2);
                transformX = -50;
            }

            setTooltipStyle({
                position: 'fixed',
                left: `${left}px`,
                top: `${iconRect.top - 10}px`,
                transform: `translate(${transformX}%, -100%)`
            });
        }
    }, [isHovered]);

    return (
        <>
            <div 
                ref={iconRef}
                className="inline-flex"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Info color="#797979" size={16} />
            </div>
            {isHovered && createPortal(
                <div 
                    ref={tooltipRef}
                    className="bg-gray-800 text-white text-sm rounded p-2 z-[9999] min-w-[200px] max-w-[300px] shadow-lg"
                    style={tooltipStyle}
                >
                    {info}
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-full">
                        <div className="border-8 border-transparent border-t-gray-800"></div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
}

export default InfoIcon;
