import { TableColumn } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { 
    key: "createdDate", 
    label: "Created Date",
    formatter: {
      type: "date"
    }
  },
  { key: "status", label: "Status" },
];

const geoBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Lift Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLift: React.FC = () => {
  const [jobConfigList, setJobConfigList] = useState([]);
  const [jobRunList, setJobRunList] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/api/jobConfig/jobType/GEO_LIFT")
      .then((jobConfigListResponse) => {
        setJobConfigList(jobConfigListResponse.data.items);
      });
    axiosInstance
      .get("/api/jobRun/jobType/GEO_LIFT")
      .then((jobRunListResponse) => {
        setJobRunList(jobRunListResponse.data.items);
      });
  }, []);
  return (
    <>
      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={geoBreadCrumbs}
        jobType={JobType.GeoLift}
        tableColumns={columns}
      />
    </>
  );
};

export default GeoLift;
