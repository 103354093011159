import React from "react";
import ReactECharts from "echarts-for-react";

interface HeatmapProps {
  data: {
    xLabels: string[];
    yLabels: string[];
    zData: number[][];
  };
}

// Helper function to capitalize labels and remove underscores
const formatLabel = (label: string) => {
  return label
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const HeatMap: React.FC<HeatmapProps> = ({ data }) => {
  const { xLabels, yLabels, zData } = data;

  // Transform zData into the format required by ECharts
  const formattedData = zData.flatMap((row, rowIndex) =>
    row.map((value, colIndex) => [colIndex, rowIndex, value])
  );

  const option = {
    tooltip: {
      position: "top",
      formatter: (params: { value: [number, number, number] }) => {
        const value = params.value[2];
        return `${value.toFixed(2)}`;
      },
    },
    grid: {
      left: "200px",
      right: "200px",
      bottom: "150px",
      top: "50px",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xLabels.map(formatLabel), // Format xLabels
      splitArea: {
        show: true,
      },
      axisLabel: {
        rotate: 45,
        interval: 0,
        fontSize: 15, // Increase font size for x-axis labels
      },
    },
    yAxis: {
      type: "category",
      data: yLabels.map(formatLabel), // Format yLabels
      splitArea: {
        show: true,
      },
      axisLabel: {
        fontSize: 15, // Increase font size for y-axis labels
      },
    },
    visualMap: {
      min: -1,
      max: 1,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15px",
      inRange: {
        color: [
          'rgba(255, 107, 107, 0.9)',
          'rgba(255, 255, 255, 0.9)',
          'rgba(20, 57, 105, 0.9)'
        ]
      },
      textStyle: {
        color: '#143969',
        fontSize: 14
      },
      outOfRange: {
        color: ['rgba(255, 107, 107, 0.9)', 'rgba(255, 255, 255, 0.9)', 'rgba(20, 57, 105, 0.9)']
      }
    },
    series: [
      {
        name: "Heatmap",
        type: "heatmap",
        data: formattedData,
        label: {
          show: true,
          formatter: function(params: any) {
            const value = params.value[2];
            return value.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
          fontSize: 12,
          fontWeight: 'bold',
          color: '#000000'
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: '#fff'
        }
      }
    ]
  };

  return (
    <ReactECharts option={option} style={{ height: "800px", width: "100%" }} />
  );
};

export default HeatMap;
