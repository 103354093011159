import React from "react";
import { Link } from "react-router-dom";

interface CollapsableSideBarListProps {
  categories: Category[];
}

export interface Item {
  name: string;
  id: string;
  url?: string;
  isBeta?: boolean;
}

export interface SubCategory {
  name: string;
  id: string;
  items: Item[];
  isBeta?: boolean;
}

export interface Category {
  name: string;
  id: string;
  subCategories: SubCategory[];
}

const CollapsableSideBarList: React.FC<CollapsableSideBarListProps> = ({
  categories,
}) => {
  return (
    <ul className="menu p-4 w-96 min-h-full text-base-content border-r-2">
      {categories.map((category) => (
        <li key={category.id}>
          <details open>
            <summary className="font-semibold text-[#143969]">{category.name}</summary>
            <ul>
              {category.subCategories.map((subCategory) => (
                <li key={subCategory.id}>
                  <details>
                    <summary className="text-[#143969]">
                      <span className={subCategory.isBeta ? "italic" : ""}>
                        {subCategory.name}
                      </span>
                      {subCategory.isBeta && <span className="ml-2 italic text-xs text-[#143969]">(Beta)</span>}
                    </summary>
                    <ul>
                      {subCategory.items.map((item) => (
                        <li key={item.id}>
                          {item.url ? (
                            <Link to={item.url}>
                              <span className={`${item.isBeta ? "italic" : ""} text-[#143969]`}>
                                {item.name}
                              </span>
                              {item.isBeta && <span className="ml-2 italic text-xs text-[#143969]">(Beta)</span>}
                            </Link>
                          ) : (
                            <span className="cursor-not-allowed">
                              <span className={`${item.isBeta ? "italic" : ""} text-[#143969]`}>
                                {item.name}
                              </span>
                              {item.isBeta && <span className="ml-2 italic text-xs text-[#143969]">(Beta)</span>}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </details>
                </li>
              ))}
            </ul>
          </details>
        </li>
      ))}
    </ul>
  );
};

export default CollapsableSideBarList;
