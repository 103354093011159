import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import axiosInstance from "../../utils/axiosInstance";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import { z } from "zod";
import { Maximize2 } from "react-feather";
import LineChart, { LineData } from "../LineChart";
import Table, { TableColumn, TableRecord } from "../Table";
import { JobType } from "../../constants/JobTypes";
import { v4 as uuidv4 } from "uuid";
import Maximize from "../Maximize";
import TopLoadingBar from "../TopLoadingBar/TopLoadingBar";
import Alert, { AlertProps } from "../Alert";
import { useNavigate, useLocation } from "react-router-dom";
import HeatMap from "../HeatMap";
import Papa from "papaparse";
import { getRandomColor, getRandomColorWithOpacity } from "../../utils/utils";

const dropDownOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

let importExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "text",
      label: "Name",
      group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list",
    },
  },
  campaignType: {
    validation: z.string().min(1, "Campaign type is required"),
    metadata: {
      type: "select",
      label: "Campaign Type",
      options: [
        { label: "National Buy", value: "national" },
        { label: "Non-National Buy", value: "non_national" }
      ],
      group: "row1",
      fieldInfo: "Type of Campaign for the geo lift analysis",
    },
  },
  geoLiftDataFile: {
    validation: z.any().refine((file) => file && file.length > 0, {
      message: "Data CSV File is required",
    }),
    metadata: {
      type: "file",
      label: "Data CSV File",
      group: "row7a",
      accepts: ".csv",
      showIf: [],
      fieldInfo: "CSV file on which you want to run the model",
    },
  },
  locationVariable: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "select",
      label: "Location Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    },
  },
  kpiVariable: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "select",
      label: "KPI Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    },
  },
  dateVariable: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "select",
      label: "Date Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row8",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    },
  },
  dateFormat: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Date Format",
      options: [
        {
          label: "MM/DD/YYYY",
          value: "%m/%d/%Y",
        },
        {
          label: "MM/DD/YY",
          value: "%m/%d/%y",
        },
        {
          label: "YYYY-MM-DD",
          value: "%Y-%m-%d",
        },
        {
          label: "DD/MM/YYYY",
          value: "%d/%m/%Y",
        },
        {
          label: "DD/MM/YY",
          value: "%d/%m/%y",
        },
        {
          label: "YYYY/MM/DD",
          value: "%Y/%m/%d",
        },
        {
          label: "DD-MM-YYYY",
          value: "%d-%m-%Y",
        },
        {
          label: "DD-MM-YY",
          value: "%d-%m-%y",
        },
        {
          label: "YYYY.MM.DD",
          value: "%Y.%m.%d",
        },
        {
          label: "DD.MM.YYYY",
          value: "%d.%m.%Y",
        },
        {
          label: "MM-DD-YYYY",
          value: "%m-%d-%Y",
        },
        {
          label: "YYYY/MM/DD HH:MM:SS",
          value: "%Y/%m/%d %H:%M:%S",
        },
        {
          label: "DD/MM/YYYY HH:MM",
          value: "%d/%m/%Y %H:%M",
        },
        {
          label: "YYYY-MM-DDTHH:MM:SS",
          value: "%Y-%m-%dT%H:%M:%S",
        },
      ],
      group: "row8",
      showIf: [],
      fieldInfo: "Date format of the date column you have selected",
    },
  },
};

let edaExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  numberOfTestMarket: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "select",
      options: [
        {
          value: "2,3,4,5",
          label: "2, 3, 4, 5"
        }
      ],
      defaultValue: "2,3,4,5",
      label: "Number of Markets in Synthetic Control",
      group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list",
    },
  },
  treatmentPeriod: {
    validation: z
      .string()
      .min(1, "Name is required")
      .max(100, "Name cannot exceed 100 characters"),
    metadata: {
      type: "select",
      options: [
        {
          value: "10,15",
          label: "10, 15"
        },
        {
          value: "8,10,12",
          label: "8, 10, 12"
        }
      ],
      defaultValue: "10,15",
      label: "Model Validation Period",
      group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list",
    },
  },
  includeMarket: {
    validation: z
      .array(dropDownOptionSchema)
      .min(1, "At least one test market must be selected"),
    metadata: {
      type: "multi-select",
      label: "Test Market",
      selectedOptions: [],
      options: [],
      group: "row2",
      fieldInfo: "Select the test markets for your geo lift analysis",
    },
  },
  excludeMarket: {
    validation: z
      .array(dropDownOptionSchema)
      .min(0, "At least one spend variable must be selected"),
    metadata: {
      type: "multi-select",
      label: "Exclude Market",
      selectedOptions: [],
      options: [],
      group: "row2",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    }
  },
  budget: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "text",
      label: "Budget",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      formatter: {
        type: "currency",
        decimalScale: 2
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    }
  },
  cpic: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "text",
      label: "Cost Per Incremental Conversion",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      formatter: {
        type: "currency",
        decimalScale: 2
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Column name that model can pick to process against any dependent variables, Example: Sales",
    }
  },
  confidence: {
    validation: z.string().min(1, "Please select response variable"),
    metadata: {
      type: "text",
      label: "Confidence",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      formatter: {
        type: "percentage",
        decimalScale: 1
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Confidence level for the analysis (e.g., 95%)",
    }
  }
};

const JobConfigCreateGeoLift = () => {

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const jobConfigId = query.get('jobConfigId');

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState<AlertProps["type"]>("success");
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  const [lineData, setLineData] = useState<LineData[]>([]);
  const [allLineData, setAllLineData] = useState<LineData[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredMarkets = allLineData.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMarketSelection = (marketName: string) => {
    const newSelectedMarkets = new Set(selectedMarkets);
    if (newSelectedMarkets.has(marketName)) {
      newSelectedMarkets.delete(marketName);
    } else {
      newSelectedMarkets.add(marketName);
    }
    setSelectedMarkets(newSelectedMarkets);
    updateChartData(newSelectedMarkets);
  };

  const handleSelectAll = () => {
    const allMarkets = new Set(allLineData.map(item => item.name));
    setSelectedMarkets(allMarkets);
    updateChartData(allMarkets);
  };

  const handleClearAll = () => {
    setSelectedMarkets(new Set());
    setLineData([]);
  };

  const updateChartData = (selectedMarkets: Set<string>) => {
    const filteredData = allLineData.filter(item => selectedMarkets.has(item.name));
    setLineData(filteredData);
  };

  const [importGeoLiftFormState, setImportGeoLiftFormState] = useState<any>([]);
  const [formSchema, setFormSchema] = useState<Record<string, ExtendedFieldConfig>>(edaExtendedFormSchema);

  useEffect(() => {
    if (importGeoLiftFormState.campaignType === 'national') {
      const { includeMarket, ...restSchema } = edaExtendedFormSchema;
      setFormSchema(restSchema);
    } else {
      setFormSchema(edaExtendedFormSchema);
    }
  }, [importGeoLiftFormState.campaignType]);

  const submitImportForm = async (data: Record<string, any>) => {
    try {
      console.log("data", data)
      setLoading(true);
      setImportGeoLiftFormState(data);

      if (data.geoLiftDataFile[0]) {
        Papa.parse(data.geoLiftDataFile[0], {
          header: true, // Treat the first row as headers
          complete: (result) => {
            const parsedData = result.data;
            const dataByLocation: { [location: string]: { date: string; value: number }[] } = {};

            // Parse the data and group by location
            parsedData.forEach((row: any) => {

              const date = row[data.dateVariable]
              const kpi = row[data.kpiVariable]
              const location = row[data.locationVariable]

              if (!dataByLocation[location]) {
                dataByLocation[location] = [];
              }
              dataByLocation[location].push({ date, value: Number(kpi) });
            });

            // Extract unique locations
            const uniqueLocations = Object.keys(dataByLocation).map((location) => ({
              label: location
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              value: location,
            }));

            // Update the form schema for includeMarket and excludeMarket with unique locations
            edaExtendedFormSchema.includeMarket.metadata.options = uniqueLocations;
            edaExtendedFormSchema.excludeMarket.metadata.options = uniqueLocations;

            // Extract unique dates from one of the locations
            const sampleLocation = Object.keys(dataByLocation)[0];
            const dateLabels = dataByLocation[sampleLocation].map((item) => item.date);
            setDates(dateLabels);

            // Create the line data for each location
            const formattedData: LineData[] = Object.keys(dataByLocation).map((location) => {
              const dataPoints = dataByLocation[location].map((item) => item.value);

              return {
                name: location,
                data: dataPoints,
                borderColor: getRandomColor(),
                backgroundColor: getRandomColorWithOpacity(0.3),
              };
            });

            setAllLineData(formattedData);
            setLineData(formattedData);
            setSelectedMarkets(new Set(formattedData.map(item => item.name)));
            if (currentStep < steps.length - 1) {
              setCurrentStep(currentStep + 1);
            }
          },
        });
      }


      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAlertMessage("Failed to import data.");
      setAlertType("error");
    }
  };


  const submitEDAForm = async (formData: Record<string, any>) => {
    console.log("formdata submit eda geo", formData);
    // Convert confidence from percentage to decimal (e.g., 9.2% -> 0.092)
    if (formData.confidence) {
      formData.confidence = (parseFloat(formData.confidence) / 100).toString();
    }
    // Only process includeMarket and excludeMarket if they exist
    if (formData.includeMarket) {
      formData.includeMarket = formData.includeMarket.map((market: any) => market.value).join(";;;");
    }
    if (formData.excludeMarket) {
      formData.excludeMarket = formData.excludeMarket.map((market: any) => market.value).join(";;;");
    }
    try {
      setLoading(true);
      const locationVariable = importGeoLiftFormState.locationVariable;
      const kpiVariable = importGeoLiftFormState.kpiVariable;
      const dateVariable = importGeoLiftFormState.dateVariable;

      const jobId = jobConfigId == undefined ? uuidv4() : jobConfigId;
      if (jobConfigId == undefined) {
        const geoLiftFormData = new FormData();
        geoLiftFormData.append("file", importGeoLiftFormState.geoLiftDataFile[0]);
        const geoLiftFilePath = jobId + "/input/geo_lift_data.csv";
        geoLiftFormData.append("path", geoLiftFilePath);
        await axiosInstance.post("/api/s3/upload", geoLiftFormData);
      }

      const payload = {
        jobConfigId: jobId,
        name: importGeoLiftFormState.name,
        jobType: JobType.GeoLift,
        dataSourceConfig: {
          dataSourceType: "FILE_CSV",
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            geoLift: {
              columnMapping: {
                date_variables: [dateVariable],
                kpi_variables: [kpiVariable],
                location_variables: [locationVariable]
              },
            },
          },
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            dateFormat: importGeoLiftFormState.dateFormat,
            buyType: importGeoLiftFormState.buyType,
            ...formData
          },
        },
        modelOutputConfig: {},
      };

      // Send the POST request using Axios
      if (jobConfigId == undefined) {
        const response = await axiosInstance.post("/api/jobConfig", payload);
      } else {
        const response = await axiosInstance.put("/api/jobConfig", payload);

      }
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      setAlertMessage(`Failed to ${jobConfigId == undefined ? "create" : "update"} Job Configuration.`);
      setAlertType("error");
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    {
      title: "Import Data",
      content: (
        <div className="py-8 px-16">
          <DynamicForm
            formName="Import Data Geo Lift Form"
            formSchema={importExtendedFormSchema}
            onSubmit={submitImportForm}
            onSubmitText={`🗃️ ${jobConfigId != undefined ? "Update " : ""}Import Data`}
            // onPrevious={prevStep}
            // onPreviousText={"Previous"}
            isLoading={loading}
          />
        </div>
      ),
    },
    {
      title: "Exploratory Data Analysis",
      content: (
        <>
          <Maximize title="Geo Market Trend">
            {dates.length > 0 && (
              <div className="min-h-[800px] flex flex-col">
                <div className="flex justify-end mb-4">
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Markets ({selectedMarkets.size}/{allLineData.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAll();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAll();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search markets..."
                          className="input input-xs input-bordered w-full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredMarkets.length > 0 ? (
                          filteredMarkets.map((item) => (
                            <div key={item.name} className="form-control">
                              <label className="label cursor-pointer justify-start gap-2">
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-xs"
                                  checked={selectedMarkets.has(item.name)}
                                  onChange={() => handleMarketSelection(item.name)}
                                />
                                <span className="label-text">{item.name}</span>
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No markets found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 relative">
                  {lineData.length > 0 ? (
                    <LineChart
                      labels={dates}
                      dataSets={lineData}
                      xAxisTitle="Date"
                      yAxisTitle="Value"
                      height="700px"
                      showSymbols={false}
                    />
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                      Please select at least one market to view the chart
                    </div>
                  )}
                </div>
              </div>
            )}
          </Maximize>
          <DynamicForm
            formName="Import Data Geo Lift Form"
            formSchema={formSchema}
            onSubmit={submitEDAForm}
            onSubmitText={`🗃️ ${jobConfigId != undefined ? "Update " : ""}Import Data`}
            // onPrevious={prevStep}
            // onPreviousText={"Previous"}
            isLoading={loading}
          />
        </>
      ),
    }
  ];

  return (
    true ? (<NavBar>
      <div className={loading ? "" : "hidden"}>
        <TopLoadingBar></TopLoadingBar>
      </div>
      <div className="container mx-auto p-12">
        <p className="text-center pb-8 text-lg font-semibold">
          Create Job - Geo Lift
        </p>
        <div className="steps w-full flex justify-between items-center mb-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`step flex-1 text-center  ${index <= currentStep ? "step-primary" : ""
                }`}
            >
              <div>{step.title}</div>
            </div>
          ))}
        </div>
        <div className="content mb-4">
          <div>{steps[currentStep].content}</div>
        </div>
      </div>
      {alertMessage && (
        <Alert
          message={alertMessage}
          type={alertType}
          callback={() => {
            setAlertMessage(undefined);
          }}
        ></Alert>
      )}
    </NavBar>) : null
  );
};

export default JobConfigCreateGeoLift;
