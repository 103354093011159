import React, { useEffect, useState } from "react";
import NavBar from "../../../../components/NavBar";
import { Link, useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../../../utils/axiosInstance";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import DynamicForm, { ExtendedFieldConfig } from "../../../../components/DynamicForm";
import { z } from "zod";
import { LOCALE_LIST } from "../../../../constants/LocaleList";
import { DMA_LIST } from "../../../../constants/DMAList";
import TopLoadingBar from "../../../../components/TopLoadingBar/TopLoadingBar";

const bidMultiplierBreadCrumbs = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Buying",
        url: "/optimization/bid-optimizer",
    },
    {
        name: "Bid Optimizer",
        url: "/optimization/bid-optimizer",
    },
];

let bidMultiplierFormSchema: Record<string, ExtendedFieldConfig> = {
    controlAdsetId: {
        validation: z.string().min(1, "Control Adset Id is required"),
        metadata: { type: "text", label: "Control Adset Id", group: "control" },
    },
    controlBudget: {
        validation: z.string().min(1, "Control Budget is required"),
        metadata: { type: "text", label: "Control Budget", group: "control" },
    },
    testAdsetId: {
        validation: z.string().min(1, "Test Adset Id is required"),
        metadata: { type: "text", label: "Test Adset Id", group: "test" },
    },
    testBudget: {
        validation: z.string().min(1, "Test Budget is required"),
        metadata: { type: "text", label: "Test Budget", group: "test" },
    }
};

let googleBidMultiplierFormSchema: Record<string, ExtendedFieldConfig> = {
    googleCustomerId: {
        validation: z.string().min(1, "Google Customer Id is required"),
        metadata: { type: "text", label: "Google Customer Id", group: "row1" },
    }
};

const BidOptimizerDashboard: React.FC = () => {
    const [bidsColumns, setBidsColumns] = useState<TableColumn[]>([]);
    const [bidsRecords, setBidsRecords] = useState<TableRecord[]>([]);
    const [bidsJson, setBidsJson] = useState<any>();
    const [jobConfigData, setJobConfigData] = useState<any>();
    const [isAlert, setAlert] = useState(false);
    const [activeTab, setActiveTab] = useState<'facebook' | 'google'>('facebook');
    const [facebookLoading, setFacebookLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const [formData, setFormData] = useState({
        controlAdsetId: '',
        controlBudget: '',
        testAdsetId: '',
        testBudget: '',
        googleControlCampaignId: '',
        googleControlBudget: '',
        googleTestCampaignId: '',
        googleTestBudget: ''
    });

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";
  
    let [breadcrumbs, setBreadcrumbs] = useState(bidMultiplierBreadCrumbs);
    const { id } = useParams<{ id: string }>(); // Extract UUID from URL
  
    useEffect(() => {
      setBreadcrumbs((prevBreadcrumbs: any) => {
        if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
          return [
            ...prevBreadcrumbs,
            { name: name, url: `/planning/marketing-mix-model/${id}` },
          ];
        }
        return prevBreadcrumbs;
      });
    }, [id]);

    useEffect(() => {
        axiosInstance.get(`/api/bid-multiplier/output/${id}`).then((response) => {
            let outputJson = response.data.result;
            if (typeof outputJson === "string") {
                try {
                    outputJson = JSON.parse(outputJson);
                    console.log("bid multipleir result :", outputJson);
                    setBidsJson(outputJson);
                    setBidsColumns(
                        Object.keys(outputJson["bid-multiplier"][0]).sort((a, b) => {
                            if (a === "Bid Value") return 1;  // Move "Bid Value" to the last position
                            if (b === "Bid Value") return -1;
                            return 0;  // Keep other keys in their original order
                        }).map((key) => ({
                            key: key.trim(),
                            label: key.trim().replaceAll("_", " "),
                        }))
                    );
                    setBidsRecords(
                        outputJson["bid-multiplier"].map((item: any) => {
                            const record: TableRecord = {};
                            Object.keys(item).forEach((key) => {
                                const value = item[key];
                                // Check if the value is a string before trimming
                                record[key.trim()] = typeof value === "string" ? value.trim() : value;
                            });
                            return record;
                        })
                    );
                } catch (error) {
                    console.error("Failed to parse JSON string:", error);
                }
            }
        });
    }, [id]);

    useEffect(() => {
        axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
            setJobConfigData(response.data);
        });
    }, [id]);

    const getRegionKey = (region: any, provider: 'google' | 'facebook') => {
        if (jobConfigData.modelParameterConfig.parameters.geoLocationType === "dma") {
            const regionItem = DMA_LIST.find(item => {
                if (item.name === region) {
                    return true;
                }
                return false;
            });
            return provider === 'facebook' ? regionItem?.key : regionItem?.gg_ads_key;
        } else {
            const regionItem = DMA_LIST.find(item => {
                if (item.name === region) {
                    return true;
                }
                return false;
            });
            return provider === 'facebook' ? regionItem?.key : regionItem?.gg_ads_key;
        }
    }

    const getLocaleKey = (locale: any, provider: 'google' | 'facebook') => {
        const localeItem = LOCALE_LIST.find(item => {
            if (item.name === locale) {
                return true;
            }
        });
        return provider === 'facebook' ? localeItem?.key : localeItem?.gg_locale;
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const onApplyBids = async () => {
        // Validate form data
        if (!formData.controlAdsetId || !formData.controlBudget || !formData.testAdsetId || !formData.testBudget) {
            alert('Please fill in all fields');
            return;
        }

        const columnMapping = jobConfigData.dataMappingConfig.mappings.bidMultiplier.columnMapping;
        const payload = {
            jobConfigId: id,
            provider: "facebookads",
            controlAdsetId: formData.controlAdsetId,
            controlBudget: parseFloat(formData.controlBudget),
            testAdsetId: formData.testAdsetId,
            testBudget: parseFloat(formData.testBudget),
            payload: bidsJson['bid-multiplier'].map((item: any) => {
                const data = {
                    age: item.age,
                    devicePlatform: columnMapping?.device_platform_varialbes?.length ? item[columnMapping.device_platform_varialbes[0]] : undefined,
                    gender: columnMapping?.gender_varialbes?.length ? item[columnMapping.gender_varialbes[0]] : undefined,
                    locale: columnMapping?.locale_varialbes?.length ? getLocaleKey(item[columnMapping.locale_varialbes[0]], 'facebook') : undefined,
                    regions: columnMapping?.region_varialbes?.length ? getRegionKey(item[columnMapping.region_varialbes[0]], "facebook") : undefined,
                    salesScaled: item['Bid Value'],
                    userDevice: columnMapping?.user_devices_varialbes?.length ? item[columnMapping.user_devices_varialbes[0]] : undefined,
                    userOS: columnMapping?.user_os_varialbes?.length ? item[columnMapping.user_os_varialbes[0]] : undefined,
                };
                return data;
            })
        };
        setFacebookLoading(true);
        try {
            console.log('Facebook Bid Optimizer Request:', payload);
            const response = await axiosInstance.post(`/api/bid-multiplier/${id}`, payload);
            console.log('Facebook Bid Optimizer Response:', response.data);
            handleAlert();
        } catch (error) {
            console.error('Facebook Bid Optimizer Error:', error);
        } finally {
            setFacebookLoading(false);
        }
    }

    const onGoogleApplyBids = async () => {
        // Validate form data
        if (!formData.googleControlCampaignId || !formData.googleControlBudget || !formData.googleTestCampaignId || !formData.googleTestBudget) {
            alert('Please fill in all fields');
            return;
        }

        const columnMapping = jobConfigData.dataMappingConfig.mappings.bidMultiplier.columnMapping;
        const payload = {
            jobConfigId: id,
            provider: "googleads",
            controlCampaignId: formData.googleControlCampaignId,
            controlBudget: parseFloat(formData.googleControlBudget),
            testCampaignId: formData.googleTestCampaignId,
            testBudget: parseFloat(formData.googleTestBudget),
            payload: bidsJson['bid-multiplier'].map((item: any) => {
                const data = {
                    age: columnMapping?.age_varialbes?.length ? item[columnMapping.age_varialbes[0]] : undefined,
                    devicePlatform: columnMapping?.device_platform_varialbes?.length ? item[columnMapping.device_platform_varialbes[0]] : undefined,
                    gender: columnMapping?.gender_varialbes?.length ? item[columnMapping.gender_varialbes[0]] : undefined,
                    locale: columnMapping?.locale_varialbes?.length ? getLocaleKey(item[columnMapping.locale_varialbes[0]], 'google') : undefined,
                    regions: columnMapping?.region_varialbes?.length ? getRegionKey(item[columnMapping.region_varialbes[0]], 'google') : undefined,
                    salesScaled: item['Bid Value'],
                    userDevice: columnMapping?.user_devices_varialbes?.length ? item[columnMapping.user_devices_varialbes[0]] : undefined,
                    userOS: columnMapping?.user_os_varialbes?.length ? item[columnMapping.user_os_varialbes[0]] : undefined,
                };
                return data;
            })
        };
        setGoogleLoading(true);
        try {
            console.log('Google Bid Optimizer Request:', payload);
            const response = await axiosInstance.post(`/api/bid-multiplier/${id}`, payload);
            console.log('Google Bid Optimizer Response:', response.data);
            handleAlert();
        } catch (error) {
            console.error('Google Bid Optimizer Error:', error);
        } finally {
            setGoogleLoading(false);
        }
    }

    const handleAlert = () => {
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
        }, 3000);
    };

    return (
        <NavBar>
            <div className={facebookLoading || googleLoading ? "" : "hidden"}>
                <TopLoadingBar></TopLoadingBar>
            </div>
            <div className="px-8 pt-8 flex flex-col">
                <div className="flex justify-between">
                    <div className="text-sm breadcrumbs self-end">
                        <ul>
                            {breadcrumbs.map((entity) => (
                                <li key={entity.name}>
                                    <Link to={entity.url} className="font-mono underline">
                                        {entity.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
            
            <div className="mx-8 mb-8">
                <div className="tabs tabs-boxed mb-4">
                    <a 
                        className={`tab text-lg font-medium ${activeTab === 'facebook' ? 'tab-active' : ''}`}
                        onClick={() => setActiveTab('facebook')}
                    >
                        Facebook
                    </a>
                    <a 
                        className={`tab text-lg font-medium ${activeTab === 'google' ? 'tab-active' : ''}`}
                        onClick={() => setActiveTab('google')}
                    >
                        Google
                    </a>
                </div>

                {activeTab === 'facebook' && (
                    <div>
                        <Maximize title="Facebook Bid Optimizer">
                            <div className="overflow-x-auto">
                                <table className="table table-zebra">
                                    <thead>
                                        <tr className="border-x">
                                            <th className="border-r py-2">Adset Type</th>
                                            <th className="border-r py-2">Adset ID</th>
                                            <th className="py-2">Budget</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-x">
                                            <td className="border-r py-1">Control</td>
                                            <td className="border-r py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Control Adset ID"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="controlAdsetId"
                                                    value={formData.controlAdsetId}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                            <td className="py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Control Budget"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="controlBudget"
                                                    value={formData.controlBudget}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="border-x border-b">
                                            <td className="border-r py-1">Test</td>
                                            <td className="border-r py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Test Adset ID"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="testAdsetId"
                                                    value={formData.testAdsetId}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                            <td className="py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Test Budget"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="testBudget"
                                                    value={formData.testBudget}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="mt-4 flex justify-center">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={onApplyBids}
                                        disabled={facebookLoading}
                                    >
                                        {facebookLoading ? (
                                            <>
                                                <span className="loading loading-spinner loading-sm mr-2"></span>
                                                Applying Bids...
                                            </>
                                        ) : (
                                            'Apply Facebook Bids'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Maximize>
                    </div>
                )}

                {activeTab === 'google' && (
                    <div>
                        <Maximize title="Google Bid Optimizer">
                            <div className="overflow-x-auto">
                                <table className="table table-zebra">
                                    <thead>
                                        <tr className="border-x">
                                            <th className="border-r py-2">Campaign Type</th>
                                            <th className="border-r py-2">Campaign ID</th>
                                            <th className="py-2">Budget</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-x">
                                            <td className="border-r py-1">Control</td>
                                            <td className="border-r py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Control Campaign ID"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="googleControlCampaignId"
                                                    value={formData.googleControlCampaignId}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                            <td className="py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Control Budget"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="googleControlBudget"
                                                    value={formData.googleControlBudget}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="border-x border-b">
                                            <td className="border-r py-1">Test</td>
                                            <td className="border-r py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Test Campaign ID"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="googleTestCampaignId"
                                                    value={formData.googleTestCampaignId}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                            <td className="py-1">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Test Budget"
                                                    className="input input-sm w-full border-0 focus:outline-none bg-transparent"
                                                    name="googleTestBudget"
                                                    value={formData.googleTestBudget}
                                                    onChange={handleInputChange}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="mt-4 flex justify-center">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={onGoogleApplyBids}
                                        disabled={googleLoading}
                                    >
                                        {googleLoading ? (
                                            <>
                                                <span className="loading loading-spinner loading-sm mr-2"></span>
                                                Applying Bids...
                                            </>
                                        ) : (
                                            'Apply Google Bids'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Maximize>
                    </div>
                )}

                <div className="mt-8">
                    <Maximize title="Bids Result">
                        <Table columns={bidsColumns} records={bidsRecords} />
                    </Maximize>
                </div>
            </div>
            
            {isAlert && (
                <div
                    role="alert"
                    className="alert alert-success absolute bottom-8 right-8 w-auto"
                >
                    <span className="ml-2">Bid Applied Successfully</span>
                </div>
            )}
        </NavBar>
    );
};

export default BidOptimizerDashboard;