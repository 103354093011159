import React from "react";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";

interface PieChartProps {
  data: number[];
  labels: string[];
  showOutline?: boolean; // Add optional showOutline prop to control outline behavior
}

const PieChartECharts: React.FC<PieChartProps> = ({ data, labels, showOutline = true }) => {
  // Function to format labels: replace "_" with space and capitalize words
  const formatLabel = (label: string) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const option: EChartsOption = {
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const value = Number(params.value).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return `${params.name}: ${value} (${params.percent.toFixed(0)}%)`;
      },
    },
    legend: {
      orient: "horizontal",
      left: "center",
    },
    series: [
      {
        name: "Data",
        type: "pie",
        radius: "50%",
        data: labels.map((label, index) => ({
          value: data[index],
          name: formatLabel(label), // Format label here
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          show: showOutline, // Control the visibility of the label lines based on the prop
        },
        label: {
          show: showOutline,
          position: "outside",
          formatter: (params: any) => {
            return `${params.name}\n${params.percent.toFixed(0)}%`;
          },
          fontSize: 14,
          fontWeight: "bold",
        },
      },
    ],
    color: [
      "#143969", // Primary adrsta-blue
      "#2E5C94", // Lighter shade of adrsta-blue
      "#4B7FBF", // Even lighter shade of adrsta-blue
      "#6FA2EA", // Lightest shade of adrsta-blue
      "#93C5FF", // Very light blue
      "#1F4D8A", // Darker shade of adrsta-blue
      "#0A2847", // Darkest shade of adrsta-blue
      "#3D5A80", // Muted blue
      "#98C1D9", // Pale blue
      "#294C71", // Deep blue
      "#5B7BA6", // Steel blue
      "#7895B2"  // Gray blue
    ],

  };

  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

export default PieChartECharts;
