import React from "react";
import ReactECharts from "echarts-for-react";

interface BarChartData {
  name: string;
  values: number[];
  color?: string;
}

interface LineChartData {
  name: string;
  values: number[];
  color: string;
}

export interface MultiBarLineChartProps {
  labels: string[];
  barDatasets: BarChartData[];
  lineDatasets: LineChartData[];
  showLabels?: boolean;
}

const MultiBarLineChartECharts: React.FC<MultiBarLineChartProps> = ({
  labels,
  barDatasets,
  lineDatasets,
  showLabels = true,
}) => {
  // Capitalize labels
  const capitalizedLabels = labels.map((label: string) =>
    label
      .split("_") // Split the label by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ") // Join the words with spaces
  );

  // Create the series for the bar chart
  const barSeries = barDatasets.map((dataset, index) => {
    // Create a gradient of blues for bars
    const colors = [
      '#143969', // adrsta-blue (primary)
      '#4B7FBF', // lighter shade
      '#6FA2EA', // even lighter
      '#94C5FF', // lightest
      '#B8D8FF', // very light blue
    ];
    
    return {
      name: dataset.name,
      type: "bar",
      data: dataset.values,
      itemStyle: {
        color: colors[index % colors.length],
        borderRadius: [3, 3, 0, 0]  // Rounded top corners
      },
      label: {
        show: showLabels,
        position: 'top',
        formatter: (params: any) => {
          const value = Number(params.value).toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          });
          return `${params.seriesName}: ${value}`;
        },
        fontSize: 12,
        fontWeight: 'bold',
        color: '#143969',
        distance: 5,
        rotate: 45,
        align: 'left',
        verticalAlign: 'middle'
      },
      barMaxWidth: 50  // Limit maximum width of bars
    };
  });

  // Create the series for the line chart
  const lineSeries = lineDatasets.map((dataset) => ({
    name: dataset.name,
    type: "line",
    data: dataset.values,
    itemStyle: {
      color: '#143969',
    },
    lineStyle: {
      color: '#143969',
      width: 2,
    },
    symbol: "circle",
    symbolSize: 6,
  }));

  // Combine bar and line series
  const series = [...barSeries, ...lineSeries];

  console.log(JSON.stringify(series));

  const option = {
    xAxis: {
      type: "category",
      data: capitalizedLabels, // Use the transformed labels
      axisLabel: {
        rotate: 45,
        interval: 0,
        color: '#143969',
        fontSize: 12,
        margin: 8,
        align: 'right'
      },
      axisLine: {
        lineStyle: {
          color: '#143969',
          width: 1
        }
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
      name: "Value",
      axisLabel: {
        color: '#143969',
        fontSize: 12
      },
      axisLine: {
        lineStyle: {
          color: '#143969',
          width: 1
        }
      },
      splitLine: {
        lineStyle: {
          color: '#E5E7EB'
        }
      }
    },
    series,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      textStyle: {
        color: '#000000',
        fontSize: 12
      }
    },
    grid: {
      left: "10%",
      right: "10%",
      bottom: "15%",
      top: "10%",
      containLabel: true
    },
  };

  return (
    <ReactECharts option={option} style={{ height: "500px", width: "100%" }} />
  );
};

export default MultiBarLineChartECharts;
