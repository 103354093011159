"use client";

import NavBar from "../components/NavBar";
import Table from "../components/Table";
import axiosInstance from "../utils/axiosInstance";
import React, { useState } from "react";
import {
  JobConfig,
  JobPendingComponentProps,
  JobRun,
} from "../models/interfaces";
import { Link, useNavigate } from "react-router-dom";
import { JobType } from "../constants/JobTypes";
import mixpanel from "mixpanel-browser";
import { Events } from "../constants/Event";

const JobConfigView: React.FC<JobPendingComponentProps> = ({
  jobConfigList,
  jobRunList,
  breadCrumbs,
  tableColumns,
  jobType,
}) => {
  const navigate = useNavigate();
  const [loadingJobs, setLoadingJobs] = useState<{ [key: string]: boolean }>({});

  const onExecuteClick = async (jobConfigId: string) => {
    setLoadingJobs((prev) => ({ ...prev, [jobConfigId]: true }));
    await axiosInstance.post(
      "/api/jobRun",
      {
        jobConfigId,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );
    mixpanel.track(Events.BUTTON_CLICK, {
      jobType,
      action: "Execute",
      jobConfigId,
    });
    setLoadingJobs((prev) => ({ ...prev, [jobConfigId]: false }));
    window.location.reload();
  };

  const onEditClick = async (jobConfigId: string) => {
    navigate(`/jobs/MMM?jobConfigId=${jobConfigId}`);
  };

  const handleViewClick = (jobType: JobType, jobConfigId: string, jobName: string) => {
    if (jobType === JobType.MarketingMixModel) {
      navigate(`/planning/marketing-mix-model/${jobConfigId}?name=${jobName}`);
    }
    if (jobType === JobType.BidOptimizer) {
      navigate(`/optimization/bid-optimizer/${jobConfigId}?name=${jobName}`);
    }
    if (jobType === JobType.GeoLift) {
      navigate(`/measurement/geo-lift/${jobConfigId}?name=${jobName}`);
    }
    mixpanel.track(Events.BUTTON_CLICK, {
      jobType,
      action: "View",
      jobConfigId,
    });
  };

  const getJobStatus = (jobConfig: JobConfig, jobRunList: JobRun[]) => {
    const jobRun = jobRunList.filter(
      (jobRun) => jobRun.jobConfigId === jobConfig.jobConfigId
    );
    if (jobRun && jobRun.length === 1) {
      if (jobRun[0].jobStatus === "RUNNING") {
        return <span>Running</span>;
      } else if (jobRun[0].jobStatus === "PENDING") {
        return <span>In Queue</span>;
      }
    }
    if (jobRun && jobRun.length === 1 && jobRun[0].jobStatus === "FAILED") {
      return (
        <span>Failed</span>
        // <button
        //   className="btn btn-sm"
        //   onClick={() => onExecuteClick(jobConfig.jobConfigId)}
        //   disabled={loadingJobs[jobConfig.jobConfigId]}
        // >
        //   {loadingJobs[jobConfig.jobConfigId] ? "Executing..." : "Execute"}
        // </button>
      );
    } else if (
      jobRun &&
      jobRun.length === 1 &&
      jobRun[0].jobStatus === "SUCCESSFUL"
    ) {
      return (
        <div>
          {jobType === JobType.MarketingMixModel && (
            <button
              className="btn btn-sm mx-4"
              onClick={() => onEditClick(jobConfig.jobConfigId)}
              disabled={loadingJobs[jobConfig.jobConfigId]}
            >
              Edit
            </button>
          )}
          <button
            className="btn btn-sm"
            onClick={() =>
              handleViewClick(jobRun[0].jobType, jobRun[0].jobConfigId, jobConfig.name)
            }
          >
            View
          </button>
        </div>
      );
    } else {
      return (
        <button
          className="btn btn-sm"
          onClick={() => onExecuteClick(jobConfig.jobConfigId)}
          disabled={loadingJobs[jobConfig.jobConfigId]}
        >
          {loadingJobs[jobConfig.jobConfigId] ? "Executing..." : "Execute"}
        </button>
      );
    }
  };

  const records = jobConfigList
    ?.sort((a, b) => {
      return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    })
    .map((jobConfig) => {
      return {
        name: jobConfig.name,
        createdDate: jobConfig.createdDate,
        status: getJobStatus(jobConfig, jobRunList),
      };
    });

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadCrumbs.map((entity: any) => (
                <li key={entity.name}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="self-end">
            <Link to={`/jobs/${jobType}`} className="w-40 mb-4">
              <button className="btn w-full">Create New Job</button>
            </Link>
          </div>
        </div>
        <div className="divider"></div>
        {records && <Table columns={tableColumns} records={records} />}
      </div>
    </NavBar>
  );
};

export default JobConfigView;
