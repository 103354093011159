import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { TableColumn } from "../../../components/Table";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";
import ScenarioTable from "../../../components/ScenarioTable";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { 
    key: "createdDate", 
    label: "Created Date",
    formatter: {
      type: "date"
    }
  },
  { key: "status", label: "Status" },
];

const nbaBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/planning/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/planning/marketing-mix-model",
  },
];

const MarketingMixModel: React.FC = () => {
  const [jobConfigList, setJobConfigList] = useState([]);
  const [jobRunList, setJobRunList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [jobConfigListResponse, jobRunListResponse] = await Promise.all([
          axiosInstance.get("/api/jobConfig/jobType/MMM"),
          axiosInstance.get("/api/jobRun/jobType/MMM")
        ]);
        
        setJobConfigList(jobConfigListResponse.data.items);
        setJobRunList(jobRunListResponse.data.items);
      } catch (error) {
        console.error("Error fetching MMM data:", error);
      }
    };
    
    fetchData();
  }, []);

  const variables = ['Budget', 'Week', 'Google Spend', 'Meta Spend'];
  const defaultValues = {
    'Scenario 1': {
      'Budget': '1000',
      'Week': '1',
      'Google Spend': '500',
      'Meta Spend': '500',
    },
    'Scenario 2': {
      'Budget': '2000',
      'Week': '2',
      'Google Spend': '1000',
      'Meta Spend': '1000',
    },
  };

  return (
    <>
      {/* <ScenarioTable variables={variables} defaultValues={defaultValues} /> */}

      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={nbaBreadCrumbs}
        tableColumns={columns}
        jobType={JobType.MarketingMixModel}
      />
    </>
  );
};

export default MarketingMixModel;
