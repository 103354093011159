import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import axiosInstance from "../../../../utils/axiosInstance";
import Maximize from "../../../../components/Maximize";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import LineChart, { LineData } from "../../../../components/LineChart";
import DynamicForm, { ExtendedFieldConfig, FieldOption } from "../../../../components/DynamicForm";
import { z } from "zod";
import { GEO_LIFT_MARKET_SELECTION, GEO_LIFT_POST_TEST_API, GEO_LIFT_POWER_PLOT_API, SEASONALITY_API } from "../../../../constants/API";
import PowerCurveChart from "../../../../components/PowerCurveChart";
import GeoLiftPowerCurve from "../../../../components/GeoLiftPowerCurve";
import Papa from "papaparse";
import { getRandomColor, getRandomColorWithOpacity } from "../../../../utils/utils";
import { cloneDeep } from "lodash";
import InfoIcon from "../../../../components/InfoIcon";

// Helper function to parse location string into array of markets
const parseLocationString = (location: string): string[] => {
  return location
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("'", "")
    .split(',')
    .map(loc => loc.trim());
};

// Helper function to format markets array back to location string
const formatMarketsToLocation = (markets: string[]): string => {
  return `(${markets.map(market => `'${market.trim()}'`).join(', ')})`;
};

let marketSelectionSchema: Record<string, ExtendedFieldConfig> = {
  duration: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Duration",
      options: [
      ],
      group: "row1",
    },
  },
  location: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Location",
      options: [
      ],
      group: "row1",
    },
  },
};

const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLiftDashboard: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";

  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<string>("pre-test");
  const [bestMarkets, setBestMarkets] = useState<TableRecord[]>([]); // State for BestMarkets records
  const [bestMarketsColumns, setBestMarketsColumns] = useState<TableColumn[]>([]); // State for BestMarkets columns
  const [detailedResults, setDetailedResults] = useState<TableRecord[]>([]); // State for DetailedResults records
  const [detailedResultsColumns, setDetailedResultsColumns] = useState<TableColumn[]>([]); // State for DetailedResults columns
  const [powerCurves, setPowerCurves] = useState<TableRecord[]>([]); // State for PowerCurves records
  const [powerCurvesColumns, setPowerCurvesColumns] = useState<TableColumn[]>([]); // State for PowerCurves columns
  const [geoLiftObsLabel, setGeoLiftObsLabel] = useState<string[]>();
  const [treatmentValue, setTreatmentValue] = useState();
  const [includeMarket, setIncludeMarket] = useState<string>("");

  const [geoLiftPostObsLabel, setGeoLiftPostObsLabel] = useState<string[]>();
  const [postTreatmentValue, setPostTreatmentValue] = useState();

  const [attLabels, setATTLabels] = useState<string[]>();
  const [estimatedValue, setEstimatedValue] = useState();

  const [powerPlotData, setPowerPlotData] = useState<any>();

  const [powerWellPlotData, setPowerWellPlotData] = useState<any>();

  const [isLoading, setLoading] = useState(false);

  const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(new Set());
  const [outputJson, setOutputJson] = useState<any>(null);
  const [lineData, setLineData] = useState<any[]>([]);
  const [chartKey, setChartKey] = useState<number>(0);
  const [allLineData, setAllLineData] = useState<LineData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [dates, setDates] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [jobConfig, setJobConfig] = useState<any>({});
  const [plotDuration, setPlotDuration] = useState<number | undefined>(undefined);

  const [loadingMarketId, setLoadingMarketId] = useState<string | null>(null);

  const [bestMarketsRecords, setBestMarketsRecords] = useState<any[]>([]);

  const [liftPercentage, setLiftPercentage] = useState<number>();
  const [averageAtt, setAverageAtt] = useState<number>();

  const [locationSearchTerm, setLocationSearchTerm] = useState("");
  const [selectedLocations, setSelectedLocations] = useState<Set<string>>(new Set());

  const [postTestStartDuration, setPostTestStartDuration] = useState<number>(0);

  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [locationOptions, setLocationOptions] = useState<string[]>([]);

  const [postTestMarketSchema, setPostTestMarketSchema] = useState<Record<string, ExtendedFieldConfig>>({
    startDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }),
      metadata: {
        type: "text",
        label: "Start Duration",
        group: "row1",
        defaultValue: "0",
      },
    },
    endDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }),
      metadata: {
        type: "text",
        label: "End Duration",
        group: "row1",
        defaultValue: "0",
      },
    },
    location: {
      validation: z.string(),
      metadata: {
        type: "select",
        label: "Best Synthetic Control",
        options: [],
        group: "row1a",
        defaultValue: "",
      },
    },
    geoPostTestFile: {
      validation: z.any(),
      metadata: {
        type: "file",
        label: "Geo Lift Post Test CSV",
        group: "row2",
        accepts: ".csv"
      },
    },
  });

  const handleLocationSelection = (location: string) => {
    // Parse the location string to get the markets
    const markets = parseLocationString(location);
    
    // Clear existing line data first
    setLineData([]);
    
    // Create a new Set with all the markets from this location group
    const newSelectedMarkets = new Set(
      allLineData.map(item => item.name).filter(item => markets.includes(item.toLowerCase().replaceAll(",", "")))
    );
    setSelectedMarkets(newSelectedMarkets);
    setSelectedLocations(new Set([location]));
    
    // Update line data with filtered data and force chart refresh
    const filteredData = allLineData.filter(item => 
      newSelectedMarkets.has(item.name)
    );
    setLineData(cloneDeep(filteredData));
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleSelectAll = () => {
    const allMarkets = new Set(
      allLineData.map(item => item.name)
    );
    setSelectedMarkets(allMarkets);
    setSelectedLocations(new Set(filteredLocations));
    
    // Reset and update line data
    setLineData([]);
    setLineData(cloneDeep(allLineData));
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleClearAllFromDashboard = () => {
    setSelectedMarkets(new Set());
    setLineData([]);
  };

  const handleSelectAllLocations = () => {
    if (filteredLocations.length > 0) {
      const firstLocation = filteredLocations[0];
      const markets = parseLocationString(firstLocation);
      setSelectedMarkets(new Set(markets));
      setSelectedLocations(new Set([firstLocation]));
    }
  };

  const handleClearAllLocations = () => {
    setSelectedLocations(new Set());
    handleClearAllFromDashboard();
  };

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const includeMarketValue = response.data.modelParameterConfig.parameters.includeMarket;
      setIncludeMarket(includeMarketValue);
    });
  }, [id]);

  const filteredLocations = bestMarkets
    .map(market => market.location)
    .filter((location, index, self) => self.indexOf(location) === index) // Get unique locations
    .filter(location => location.toLowerCase().includes(locationSearchTerm.toLowerCase()));

  useEffect(() => {
    if (bestMarketsRecords.length > 0) {
      const formattedBestMarketsRecords = bestMarketsRecords
        .slice(0, 10)  // Limit to 10 records
        .map((record: any) => ({
          ...record,
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
          actions: (
            <button
              onClick={() => onSubmit(record)}
              className="btn btn-primary mt-2 w-40 relative"
              disabled={loadingMarketId === record.ID}
            >
              <div className="flex items-center justify-center space-x-2">
                {loadingMarketId === record.ID && (
                  <div className="loading loading-spinner loading-xs"></div>
                )}
                <span>Generate Test Control</span>
              </div>
            </button>
          )
        }));
      setBestMarkets(formattedBestMarketsRecords);
    }
  }, [bestMarketsRecords, loadingMarketId]);

  useEffect(() => {
    if (bestMarkets.length > 0) {
      const uniqueLocations = new Set(bestMarkets.map(market => market.location));
      setSelectedLocations(uniqueLocations);
    }
  }, [bestMarkets]);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const includeMarketValue = response.data.modelParameterConfig.parameters.includeMarket;
      setIncludeMarket(includeMarketValue);
    });
  }, [id]);

  useEffect(() => {
    if (bestMarkets && bestMarkets.length > 0) {
      // Get unique locations and durations from bestMarkets
      const locations = Array.from(new Set(bestMarkets.map((record: any) => record.location)))
        .map(loc => loc.replaceAll("(", "").replaceAll(")", "").replaceAll("'", ""));
      const durations = Array.from(new Set(bestMarkets.map((record: any) => record.duration)));

      // Update the postTestMarketSchema with new options
      setPostTestMarketSchema(prevSchema => ({
        ...prevSchema,
        location: {
          ...prevSchema.location,
          metadata: {
            ...prevSchema.location.metadata,
            options: locations.map(loc => ({ label: loc, value: loc })),
            defaultValue: selectedLocation || locations[0]
          }
        },
      }));
    }
  }, [bestMarkets, selectedLocation]);

  const onSubmit = async (record: any) => {
    console.log("on submit", record);
    setLoadingMarketId(record.ID);
    const marketSelectionPayload = {
      marketSelectionId: record.ID,
      marketName: selectedLocation,
      jobConfigId: id,
      jobConfig: jobConfig,
      tenantId: '00000000-0000-0000-0000-000000000000'
    }
    axiosInstance.post(GEO_LIFT_MARKET_SELECTION, marketSelectionPayload).then((response) => {
      const geoLiftPlot = response.data.lift_plot;
      const powerPlot = response.data.power_plot;
      const liftPercentage = response.data.lift_percentage;
      console.log(geoLiftPlot);
      console.log(JSON.stringify(powerPlot));

      setPowerPlotData(powerPlot);

      const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
      setGeoLiftObsLabel(labels);
      const c_obsData = Object.values(geoLiftPlot.c_obs);
      const t_obsData = Object.values(geoLiftPlot.t_obs);

      setPlotDuration((t_obsData.length - record.duration) as any);

      const dataSets = [
        {
          lineType: "dashed",
          name: "Synthetic Control", // Name for legend
          data: c_obsData, // y-axis values
          borderColor: "#d6279a", // Line color (blue for example)
          backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
        },
        {
          name: `Test Location (${selectedLocation})`, // Name for legend with selected location
          data: t_obsData, // y-axis values
          borderColor: "#006400", // Line color (blue for example)
          backgroundColor: "rgba(59, 2, 246, 0.0)" // Background fill color (light blue)
        },
      ];

      setTreatmentValue(dataSets as any);

      setLiftPercentage(liftPercentage);

      setLoading(false);
      setLoadingMarketId(null);
    }).catch((e) => {
      console.log("error :", e);
      setLoadingMarketId(null);
      setLoading(false);
    });

    axiosInstance.post(GEO_LIFT_POWER_PLOT_API, marketSelectionPayload).then((response) => {
      setPowerWellPlotData(response.data)

      setLoading(false);
    }).catch((e) => {
      console.log("error :", e);
      setLoadingMarketId(null);
      setLoading(false);
    });
  };

  const onGeoPostTest = async (data: Record<string, any>) => {

    setLoading(true);

    if (data.geoPostTestFile[0]) {
      Papa.parse(data.geoPostTestFile[0], {
        header: true, // Treat the first row as headers
        complete: (result) => {
          const parsedData = result.data;
          const dataByLocation: { [location: string]: { date: string; value: number }[] } = {};

          // Parse the data and group by location
          parsedData.forEach((row: any) => {
            const { location, Y, date } = row;
            if (!dataByLocation[location]) {
              dataByLocation[location] = [];
            }
            dataByLocation[location].push({ date, value: Number(Y) });
          });

          // Extract unique locations
          const uniqueLocations = Object.keys(dataByLocation).map((location) => ({
            label: location
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            value: location,
          }));

          // Extract unique dates from one of the locations
          const sampleLocation = Object.keys(dataByLocation)[0];
          const dateLabels = dataByLocation[sampleLocation].map((item) => item.date);
          setDates(dateLabels);

          // Create the line data for each location
          const formattedData: LineData[] = Object.keys(dataByLocation).map((location) => {
            const dataPoints = dataByLocation[location].map((item) => item.value);

            return {
              name: location,
              data: dataPoints,
              borderColor: getRandomColor(), // Assign random colors for each line
              backgroundColor: getRandomColorWithOpacity(0.3), // Background color with opacity
            };
          });

          setAllLineData(formattedData);
          setLineData(formattedData);
          setSelectedMarkets(new Set(formattedData.map(item => item.name)));
        },
      });
    }


    const geoLiftPostTestFormData = new FormData();

    if (data.location === "") {
      data.location = selectedLocation; // Use the currently selected location instead of first location
    }

    const matchingMarket = bestMarkets.find((record: any) => {
      const cleanedLocation = record.location
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("'", "")
        .trim();
      return cleanedLocation === data.location;
    });

    const marketId = matchingMarket ? matchingMarket.ID : null; setLoading(true);

    geoLiftPostTestFormData.append("marketSelectionId", marketId);
    geoLiftPostTestFormData.append("startDuration", data.startDuration);
    geoLiftPostTestFormData.append("endDuration", data.endDuration);
    geoLiftPostTestFormData.append("marketsList", data.location);
    geoLiftPostTestFormData.append("csv_file", data.geoPostTestFile[0]);
    geoLiftPostTestFormData.append("jobConfigId", id as string);
    geoLiftPostTestFormData.append("tenantId", '00000000-0000-0000-0000-000000000000');
    geoLiftPostTestFormData.append("market_name", selectedLocation);

    setPostTestStartDuration(Number(data.startDuration));

    // Send the POST request using Axios
    const geoLiftPostTestResponse = await axiosInstance.post(
      GEO_LIFT_POST_TEST_API,
      geoLiftPostTestFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    ).catch((error) => {
      console.error("Error in post test API:", error);
      setLoading(false); // Reset loading state on error
      throw error; // Re-throw to prevent further execution
    });

    const geoLiftPlot = geoLiftPostTestResponse.data.lift_plot;

    const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
    setGeoLiftPostObsLabel(labels);
    const c_obsData = Object.values(geoLiftPlot.c_obs);
    const t_obsData = Object.values(geoLiftPlot.t_obs);

    const dataSets = [
      {
        name: "Synthetic Control", // Name for legend
        lineType: "dashed",
        data: c_obsData, // y-axis values
        borderColor: "#d6279a", // Dark pink for Control line color
        backgroundColor: "rgba(214, 39, 154, 0)" // Light pink background fill color with slight opacity
      },
      {
        name: `Test Location (${selectedLocation})`, // Name for legend with selected location
        data: t_obsData, // y-axis values
        borderColor: "#006400", // Dark green for Treatment line color
        backgroundColor: "rgba(0, 100, 0, 0)" // Light green background fill color with slight opacity
      },
    ];

    setPostTreatmentValue(dataSets as any);

    const attPlot = geoLiftPostTestResponse.data.att_plot;
    const attLabels = Object.values(attPlot.Time).map((time: any) => time.toString());
    setATTLabels(attLabels);
    const estimateData = Object.values(attPlot.Estimate).map((value: any) =>
      parseFloat(value.toFixed(2))
    );
    const estimatedDataSets = [
      {
        type: "line",
        lineType: "dashed",
        name: "ATT", // Name for legend
        data: estimateData, // y-axis values
        borderColor: "#3b82f6", // Line color (blue for example)
        backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
      }
    ];

    setEstimatedValue(estimatedDataSets as any);

    // Update lift percentage and average ATT from the existing response
    setLiftPercentage(geoLiftPostTestResponse.data.lift_percentage);
    setAverageAtt(geoLiftPostTestResponse.data.average_att);

    setLoading(false);
  };

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
        return [
          ...prevBreadcrumbs,
          { name: name, url: `/measurement/geo-lift/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      setJobConfig(response.data);
    });
    axiosInstance.get(`/api/geo-lift/output/${id}`).then((response) => {
      let outputJson = response.data.result;
      if (typeof outputJson === "string") {
        try {
          outputJson = JSON.parse(outputJson);
        } catch (e) {
          console.error("Error parsing JSON:", e);
        }
      }
      console.log("outputJson:", outputJson);
      setOutputJson(outputJson);
      const bestMarketsColumns: TableColumn[] = [
        { key: "rank", label: (
          <span className="flex items-center gap-1">
            Rank
            <InfoIcon info="Ranking of the market based on its performance metrics" />
          </span>
        ), decimal: 0 },
        { key: "formattedLocation", label: (
          <span className="flex items-center gap-1">
            Location
            <InfoIcon info="The control markets represent the locations selected for the augmented synthetic control group, which serve as the performance benchmark." />
          </span>
        ) },
        { key: "duration", label: (
          <span className="flex items-center gap-1">
            Duration
            <InfoIcon info="The test duration specifies the marketing campaign's operational period, measured in either weeks or days." />
          </span>
        ), decimal: 0 },
        { key: "AvgATT", label: (
          <span className="flex items-center gap-1">
            Avg. Lift Value
            <InfoIcon info="The average lift value measures the aggregate impact between test and synthetic control markets, normalized across campaign duration and number of control markets. For instance, a total lift of 4,667 units over a 15-day campaign with two control markets yields an average daily lift per market of 155.56 units (4,667 ÷ (15 × 2))." />
          </span>
        ) },
        { key: "EffectSize", label: (
          <span className="flex items-center gap-1">
            Effect Size
            <InfoIcon info="The lift simulation parameter defines the target lift percentage for the augmented synthetic control model. The parameter ranges from 0.0 (simulating no lift) to 0.25 (simulating 25% lift), with 0.05 incremental steps. These increments enable systematic evaluation of model performance across different lift scenarios." />
          </span>
        ) },
        { key: "Investment", label: (
          <span className="flex items-center gap-1">
            Investment
            <InfoIcon info="The required test investment is determined by multiplying three factors: the cost per incremental conversion (CPIC), total baseline KPI volume in test locations, and the target effect size parameter. This calculation ensures adequate statistical power for the test." />
          </span>
        ), prefix: "$" },
        { key: "Power", label: (
          <span className="flex items-center gap-1">
            Power
            <InfoIcon info="To assess the effectiveness of the marketing campaign, we measure its predictive power at each time interval. A prediction is deemed statistically significant if its confidence level is at least equal to the initial confidence threshold. The overall campaign performance metric is derived by averaging these individual time-point power values over the entire campaign duration." />
          </span>
        ), decimal: 0 },
        { key: "abs_lift_in_zero", label: (
          <span className="flex items-center gap-1">
            Predicted - Actual Lift
            <InfoIcon info="Difference between predicted and actual lift values" />
          </span>
        ), decimal: 3 },
        { key: "actions", label: "Actions" },
      ];

      // Set up the table records for BestMarkets
      const bestMarketsRecords = outputJson.BestMarkets || [];
      console.log("bestMarketsRecords:", bestMarketsRecords);
      const formattedBestMarketsRecords = bestMarketsRecords
        .slice(0, 10)  // Limit to 10 records
        .map((record: any) => ({
          ...record,
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
        }));
      setBestMarketsRecords(formattedBestMarketsRecords);
      setBestMarketsColumns(bestMarketsColumns);

      const uniqueLocations: string[] = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.location))
      );

      const uniqueDuration: number[] = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.duration))
      );

      marketSelectionSchema.location.metadata.options = uniqueLocations.map((location) => {
        const formattedLabel = location
          .replace(/[()']/g, '')
          .split(',')
          .map(city => city.trim())
          .map(city => city.charAt(0).toUpperCase() + city.slice(1))
          .join(', ');

        return {
          value: location,
          label: formattedLabel
        } as FieldOption;
      });


      marketSelectionSchema.duration.metadata.options = uniqueDuration.map((duration: number) => ({
        value: duration.toString(),
        label: duration.toString()
      }));

      postTestMarketSchema.location.metadata.options = marketSelectionSchema.location.metadata.options;

      // Fix: Set default values as strings
      const firstLocation = uniqueLocations[0] || "";
      
      postTestMarketSchema.location.metadata.defaultValue = firstLocation;

      setRefresh(!refresh);

      // Set up the table columns for DetailedResults
      const detailedResultsColumns: TableColumn[] = [
        { key: "EffectSize", label: "Effect Size" },
        { key: "Investment", label: "Investment", prefix: "$" },
        { key: "ScaledL2Imbalance", label: "Scaled L2 Imbalance" },
        { key: "att_estimator", label: "ATT Estimator" },
        { key: "cpic", label: "CPIC" },
        { key: "detected_lift", label: "Detected Lift" },
        { key: "duration", label: "Duration", decimal: 0 },
        { key: "location", label: "Location" },
        { key: "pvalue", label: "P-Value" },
        { key: "treatment_start", label: "Treatment Start" },
      ];

      // Set up the table records for DetailedResults
      const detailedResultsRecords = outputJson.DetailedResults || [];
      setDetailedResultsColumns(detailedResultsColumns);
      setDetailedResults(detailedResultsRecords);

      // Set up the table columns for PowerCurves
      const powerCurvesColumns: TableColumn[] = [
        { key: "AvgATT", label: (
          <span className="flex items-center gap-1">
            Avg. Lift Value
            <InfoIcon info="The average lift value measures the aggregate impact between test and synthetic control markets, normalized across campaign duration and number of control markets. For instance, a total lift of 4,667 units over a 15-day campaign with two control markets yields an average daily lift per market of 155.56 units (4,667 ÷ (15 × 2))." />
          </span>
        ) },
        { key: "AvgDetectedLift", label: (
          <span className="flex items-center gap-1">
            Avg Detected Lift (%)
            <InfoIcon info="The percentage lift represents the relative performance difference, calculated by dividing the absolute difference between test market KPI and predicted synthetic control values by the total predicted synthetic control baseline. This normalization allows for comparable measurement of campaign effectiveness." />
          </span>
        ) },
        { key: "EffectSize", label: (
          <span className="flex items-center gap-1">
            Effect Size
            <InfoIcon info="The lift simulation parameter defines the target lift percentage for the augmented synthetic control model. The parameter ranges from 0.0 (simulating no lift) to 0.25 (simulating 25% lift), with 0.05 incremental steps. These increments enable systematic evaluation of model performance across different lift scenarios." />
          </span>
        ) },
        { key: "Investment", label: (
          <span className="flex items-center gap-1">
            Investment
            <InfoIcon info="The required test investment is determined by multiplying three factors: the cost per incremental conversion (CPIC), total baseline KPI volume in test locations, and the target effect size parameter. This calculation ensures adequate statistical power for the test." />
          </span>
        ), prefix: "$" },
        { key: "duration", label: (
          <span className="flex items-center gap-1">
            Duration
            <InfoIcon info="The test duration specifies the marketing campaign's operational period, measured in either weeks or days." />
          </span>
        ), decimal: 0 },
        { key: "formattedLocation", label: (
          <span className="flex items-center gap-1">
            Location
            <InfoIcon info="The control markets represent the locations selected for the augmented synthetic control group, which serve as the performance benchmark." />
          </span>
        ) },
        { key: "power", label: (
          <span className="flex items-center gap-1">
            Power
            <InfoIcon info="To assess the effectiveness of the marketing campaign, we measure its predictive power at each time interval. A prediction is deemed statistically significant if its confidence level is at least equal to the initial confidence threshold. The overall campaign performance metric is derived by averaging these individual time-point power values over the entire campaign duration." />
          </span>
        ), decimal: 0 },
      ];

      // Set up the table records for PowerCurves
      const powerCurvesRecords = outputJson.PowerCurves || [];
      console.log("Raw power curves:", powerCurvesRecords.map((r: any) => ({ investment: r.Investment, type: typeof r.Investment })));
      const formattedPowerCurvesRecords = powerCurvesRecords
        .filter((record: any) => {
          const investment = parseFloat(record.Investment);
          return !isNaN(investment) && investment > 0;
        })
        .slice(0, 10)  // Limit to 10 records
        .map((record: any) => ({
          ...record,
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
        }));

      setPowerCurvesColumns(powerCurvesColumns);
      setPowerCurves(formattedPowerCurvesRecords);
    });
  }, [id]);

  useEffect(() => {
    if (allLineData.length === 0) return;

    const filteredData = allLineData.filter(item => {
      const marketSelected = selectedMarkets.has(item.name);
      return marketSelected;
    });

    setLineData([]);
    setLineData(cloneDeep(filteredData));
  }, [selectedMarkets, allLineData]);

  const filteredMarkets = allLineData.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMarketSelection = (marketName: string) => {
    const newSelectedMarkets = new Set(selectedMarkets);
    if (newSelectedMarkets.has(marketName)) {
      newSelectedMarkets.delete(marketName);
    } else {
      newSelectedMarkets.add(marketName);
    }
    setSelectedMarkets(newSelectedMarkets);
    updateChartData(newSelectedMarkets);
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleSelectAllMarkets = () => {
    const allMarkets = new Set(
      allLineData.map(item => item.name)
    );
    setSelectedMarkets(allMarkets);
    setSelectedLocations(new Set(filteredLocations));
    
    // Reset and update line data
    setLineData([]);
    setLineData(cloneDeep(allLineData));
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleClearAll = () => {
    setSelectedMarkets(new Set());
    setLineData([]);
  };

  const updateChartData = (selectedMarkets: Set<string>) => {
    const filteredData = allLineData.filter(item => selectedMarkets.has(item.name));
    setLineData(filteredData);
  };

  useEffect(() => {
    if (!outputJson) return;

    // Extract unique locations from the response keys
    const locations = Object.keys(outputJson)
      .filter(key => key.includes("/BestMarkets"))
      .map(key => key.split("/")[0]);
    
    setLocationOptions(locations);
    if (locations.length > 0) {
      setSelectedLocation(locations[0]); // Set first location as default
    }
  }, [outputJson]);

  // Filter results based on selected location
  const getFilteredResults = () => {
    if (!outputJson || !selectedLocation) return { bestMarkets: [], powerCurves: [] };
    
    return {
      bestMarkets: outputJson[`${selectedLocation}/BestMarkets`] || [],
      powerCurves: outputJson[`${selectedLocation}/PowerCurves`] || []
    };
  };

  useEffect(() => {
    if (!selectedLocation) return;

    const { bestMarkets, powerCurves } = getFilteredResults();
    
    // Format and set best markets data
    const formattedBestMarkets = bestMarkets
      .slice(0, 10)  // Limit to 10 records
      .map((record: any) => ({
        ...record,
        formattedLocation: record.location
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("'", "")
          .split(',')
          .map((loc: string, index: number) => (
            <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
              {loc.trim()}<br />
            </span>
          )),
      }));
    setBestMarketsRecords(formattedBestMarkets);

    // Format and set power curves data
    const formattedPowerCurves = powerCurves
      .filter((record: any) => {
        const investment = parseFloat(record.Investment);
        return !isNaN(investment) && investment > 0;
      })
      .slice(0, 10)  // Limit to 10 records
      .map((record: any) => ({
        ...record,
        formattedLocation: record.location
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("'", "")
          .split(',')
          .map((loc: string, index: number) => (
            <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
              {loc.trim()}<br />
            </span>
          )),
      }));
    setPowerCurves(formattedPowerCurves);
  }, [selectedLocation, outputJson]);

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between items-center mb-6">
        <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.url}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {locationOptions.length > 0 && (
            <div className="flex items-center">
              <label className="mr-2 text-gray-600">
                {jobConfig?.modelParameterConfig?.parameters?.includeMarket ? "Test Markets:" : "Centroid Market:"}
              </label>
              <div className="relative">
                <select
                  className="appearance-none border-[0.5px] border-gray-200 rounded-md pl-3 pr-7 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-400"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  {locationOptions.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="divider"></div>
        <div className="tabs tabs-boxed mb-4">
          <a 
            className={`tab text-lg font-medium ${activeTab === "pre-test" ? "tab-active" : ""}`} 
            onClick={() => handleTabClick("pre-test")}
          >
            Pre Campaign
          </a>
          <a 
            className={`tab text-lg font-medium ${activeTab === "post-test" ? "tab-active" : ""}`}
            onClick={() => handleTabClick("post-test")}
          >
            Post Campaign
          </a>
        </div>
        
        <div id="pre-test" className={`tab-content ${activeTab === "pre-test" ? "block" : "hidden"}`}>
          {powerCurves && powerCurves.length > 0 && (
            <Maximize title="Power Curves">
              <div className="w-full pt-4 h-72">
                <Table columns={powerCurvesColumns} records={powerCurves} size="medium" />
              </div>
            </Maximize>
          )}
          {bestMarkets && bestMarkets.length > 0 && (
            <Maximize title="Best Markets">
              <div className="w-full pt-4 h-72">
                <Table columns={bestMarketsColumns} records={bestMarkets} size="medium" />
              </div>
            </Maximize>
          )}

          {geoLiftObsLabel && treatmentValue && (
            <Maximize title={`Test vs Synthetic Control Graph (Lift: ${liftPercentage?.toFixed(2)}%)`}>
              <LineChart
                key={`control-treatment-${chartKey}`}
                labels={geoLiftObsLabel as any}
                dataSets={treatmentValue as any}
                xAxisTitle="Time"
                yAxisTitle={`Test Location`}
                highlightAreaFrom={plotDuration}
                verticalLineAt={plotDuration}
              />
            </Maximize>
          )}

          {powerPlotData && (
            <Maximize title="Power Plot">
              <PowerCurveChart
                investmentData={Object.values(powerPlotData.Investment)}
                effectSizeData={Object.values(powerPlotData.EffectSize)}
                powerData={Object.values(powerPlotData.Power)}
                height="500px"
              />
            </Maximize>
          )}

          {powerWellPlotData && (
            <Maximize title="Geo Lift Power Curve">
              <GeoLiftPowerCurve data={powerWellPlotData} />
            </Maximize>
          )}
        </div>

        <div id="post-test" className={`tab-content ${activeTab === "post-test" ? "block" : "hidden"}`}>
          {bestMarkets && bestMarkets.length > 0 && postTestMarketSchema && (
            <div>
              <DynamicForm
                formName="Create Job Form"
                formSchema={postTestMarketSchema}
                onSubmit={onGeoPostTest}
                onSubmitText={"Analyse Post Test Data"}
                isLoading={isLoading}
              />
            </div>
          )}

          {/* Divider between form and charts */}
          <div className="my-8">
            <div className="border-t border-gray-200"></div>
          </div>

          {dates && dates.length > 0 && allLineData.length > 0 && (
            <Maximize title="Geo Market Trend">
              <div className="min-h-[400px]">
                <div className="flex justify-end mb-4 gap-2">
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Markets ({selectedMarkets.size}/{allLineData.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAllMarkets();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAllFromDashboard();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search markets..."
                          className="input input-xs input-bordered w-full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredMarkets.length > 0 ? (
                          filteredMarkets.map((item) => (
                            <div key={item.name} className="form-control">
                              <label className="label cursor-pointer justify-start gap-2">
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-xs"
                                  checked={selectedMarkets.has(item.name)}
                                  onChange={() => handleMarketSelection(item.name)}
                                />
                                <span className="label-text">{item.name}</span>
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No markets found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Locations ({selectedLocations.size}/{filteredLocations.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAllLocations();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAllLocations();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search locations..."
                          className="input input-xs input-bordered w-full"
                          value={locationSearchTerm}
                          onChange={(e) => setLocationSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredLocations.length > 0 ? (
                          filteredLocations.map((location) => {
                            const markets = parseLocationString(location);
                            return (
                              <div key={location} className="form-control">
                                <label className="label cursor-pointer justify-start gap-2">
                                  <input
                                    type="radio"
                                    name="location-radio"
                                    className="radio radio-xs"
                                    checked={selectedLocations.has(location)}
                                    onChange={() => handleLocationSelection(location)}
                                  />
                                  <div className="flex flex-col">
                                    <span className="label-text font-medium">Location Group {filteredLocations.indexOf(location) + 1}</span>
                                    <div>
                                      {markets.map((loc, index) => (
                                        <span 
                                          key={index} 
                                          className="block text-xs" 
                                          style={{ 
                                            backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4',
                                            padding: '2px 4px',
                                            marginBottom: '1px'
                                          }}
                                        >
                                          {loc}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No locations found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                
                {selectedMarkets.size === 0 ? (
                  <div className="flex items-center justify-center h-[400px] text-gray-500">
                    Please select at least one market to view the chart
                  </div>
                ) : (
                  <LineChart
                    key={chartKey}
                    labels={dates}
                    dataSets={lineData}
                    xAxisTitle="Date"
                    yAxisTitle="Value"
                    highlightAreaFrom={postTestStartDuration}
                    showSymbols={true}
                    height="400px"
                  />
                )}
              </div>
            </Maximize>
          )}

          {geoLiftPostObsLabel && postTreatmentValue && (
            <Maximize title="Test vs Control">
              <div>
                <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg">
                  <span className="font-medium text-blue-600">
                    Lift Percentage: {liftPercentage?.toFixed(2)}%
                  </span>
                </div>
                <LineChart
                  key={`control-treatment-${chartKey}`}
                  labels={geoLiftPostObsLabel as any}
                  dataSets={postTreatmentValue as any}
                  xAxisTitle="Time"
                  yAxisTitle="Control Observations (c_obs)"
                  highlightAreaFrom={postTestStartDuration - 1}
                  verticalLineAt={postTestStartDuration - 1}
                  showSymbols={true}
                  height="500px"
                />
              </div>
            </Maximize>
          )}

          {attLabels && estimatedValue && (
            <Maximize title="Average Estimated Treatment Effect">
              <div>
                <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg">
                  <span className="font-medium text-blue-600">
                    Average ATT: {averageAtt?.toFixed(2)}
                  </span>
                </div>
                <LineChart
                  key={`treatment-effect-${chartKey}`}
                  labels={attLabels as any}
                  dataSets={estimatedValue as any}
                  xAxisTitle="Time"
                  yAxisTitle="Average ATT"
                  highlightAreaFrom={postTestStartDuration - 1}
                  verticalLineAt={postTestStartDuration - 1}
                  showSymbols={true}
                  height="400px"
                />
              </div>
            </Maximize>
          )}
        </div>
      </div>
    </NavBar>
  );
};

export default GeoLiftDashboard;
